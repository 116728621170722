<template>
  <el-dialog title="下载智梯卫士APP" :visible="visible" :close-on-click-modal="false" append-to-body width="860px" top="8vh"
    center @close="closeDialog">
    <div style="height: 50vh; overflow-y: scroll;" class="scrollElement">
      <div style=" width: 76%; margin: 15px auto;">
        <p style="color: #Fff; line-height: 25px">
          智梯卫士是一款优秀的数字化电梯物联网安全监管系统工具软件。它能实时查看电梯监控、运行状态，实现智慧困人报警，快速救援通知，满足无纸化维保、按需维保、维修等多种电梯日常运行管理要求。</p>
        <el-row>
          <el-col :span="12" class="flexCenter">
            <el-button @click="goDownload('ios')" type="primary">
              <div style="display:flex;align-items: center;">
                <el-image :src="require('@/assets/download/apple.png')" style="width: 25px; margin: 0px 10px"
                  fit="cover" />
                <span>APP Store下载</span>
              </div>
            </el-button>
            <el-image :src="require('@/assets/appstore.png')" fit="cover" style="width: 210px; margin: 25px 0 10px" />
            <span style="color: #Fff;">APP Store下载智梯卫士APP</span>
          </el-col>
          <el-col :span="12" class="flexCenter">
            <el-button @click="goDownload('android')" type="primary">
              <div style="display:flex;align-items: center;">
                <el-image :src="require('@/assets/download/Android.png')" style="width: 25px;margin: 0px 10px"
                  fit="cover" />
                <span>安卓版下载</span>
              </div>
            </el-button>
            <Qrcode v-if="appUrl" :url="appUrl" :logoSrc="require('@/assets/applogo.png')" :size="210"
              style="margin: 25px 0 10px">
            </Qrcode>
            <div v-else style="height: 245px;"></div>
            <span style="color: #Fff;">扫码下载智梯卫士APP</span>
          </el-col>
        </el-row>
      </div>
      <p style="color: #Fff; text-align: right; margin-right:30px">*小米、荣耀、华为、OPPO、ViVO可在手机应用市场下载。</p>
    </div>
    <div slot="footer" class="dialog-footer">
    </div>
  </el-dialog>
</template>

<script>
  import Qrcode from '@/components/qrcode';
  export default {
    components: {
      Qrcode
    },
    props: [''],
    data() {
      return {
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        loading: true,
        visible: true,
        appUrl: ''
      }
    },
    created() {
      this.getDictionar(['ANDROID_APP'])
    },

    methods: {
      // 获取字典数据
      getDictionar(code) {
        // code = code.join()
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {

            if (res.data.code === 200) {
              const {
                data: {
                  data
                },
              } = res

              this.appUrl = data.ANDROID_APP[0].value
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      goDownload(type) {
        let url
        if (type == 'ios') {
          url = 'https://apps.apple.com/cn/app/%E6%99%BA%E6%A2%AF%E5%8D%AB%E5%A3%AB/id1661052067'
        } else {
          url = this.appUrl
        }
        window.open(url)
      },
      closeDialog() {
        this.visible = false
        this.$emit('close')
      },
    },
  }
</script>
<style lang='scss' scoped>
  .noticeBox {
    margin-bottom: 15px;
  }

  .flexCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>
