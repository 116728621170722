<template>
  <div class="admin-project-myproject-container">
    <el-dialog v-if='dioDetails' :close-on-click-modal='false' :visible.sync="dioDetails" append-to-body width="1200px"
      top='15vh' @close="closeDetails">
      <div class="tabBut" style="text-align: center">
        <el-button @click="detailsItem.seleId=1" :type='1 == detailsItem.seleId ? "primary" : ""'>报警消息</el-button>
        <!-- {{detailsItem.seleId}} -->
        <el-button v-show="dioDetailsItem.sosTypeName == '安全回路'" @click="detailsItem.seleId=2"
          :type='2 == detailsItem.seleId ? "primary" : ""'>运行参数</el-button>
        <el-button @click="detailsItem.seleId=3" :type='3==detailsItem.seleId ? "primary" : ""'>处置流程</el-button>
        <el-button v-if="dioDetailsItem.voiceRescordDTOS.length > 0 || dioDetailsItem.eleSmsRescordDTOS.length > 0"
          @click="detailsItem.seleId=4" :type='4==detailsItem.seleId ? "primary" : ""'>报警通知</el-button>
      </div>
      <div style="height: 50vh;margin-top: 10px;" class="scrollElement"
        :class="[detailsItem.seleId==1?'yeScroll':'noScroll']">
        <AlarmMessage v-if="detailsItem.seleId==1" :dioData="dioDetailsItem" />
        <Operational v-else-if="detailsItem.seleId==2" :dioData="dioDetailsItem" />
        <Process v-else :dioData="dioDetailsItem" />
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: center;">
        <div v-if="detailsItem.seleId == 1 && dioDetailsItem.sosStatus === 1">
          <template v-if="
          	dioDetailsItem.sosType == 95 ||
          	dioDetailsItem.sosType == 0 ||
          	dioDetailsItem.sosType == 10 ||
          	dioDetailsItem.sosType == 7 ||
          	dioDetailsItem.sosType == 3 ||
          	dioDetailsItem.sosType == 333 ||
          	dioDetailsItem.sosType == 4 ||
          	dioDetailsItem.sosType == 5 ||
          	dioDetailsItem.sosType == 11 ||
          	dioDetailsItem.sosType == 12 ||
          	dioDetailsItem.sosType == 13 ||
            dioDetailsItem.sosType == 96 ||
            dioDetailsItem.sosType == 1 ||
            dioDetailsItem.sosType == 2 ||
            dioDetailsItem.sosType == 14 ||
            dioDetailsItem.sosType == 999 ||
            dioDetailsItem.sosType == 98 ||
            dioDetailsItem.sosType == 21 ||
            dioDetailsItem.sosType == 20 ||
            dioDetailsItem.sosType == 19 ||
            dioDetailsItem.sosType == 15 ||
            dioDetailsItem.sosType == 109 ||
            dioDetailsItem.sosType == 22
          ">
            <el-button :disabled="dioDetailsItem.sosStatus!=1" size="mini" type="primary" @click="sosClick(1)"
              v-if="dioDetailsItem.sosType != 109&&dioDetailsItem.sosType != 15&&dioDetailsItem.sosType != 13&&dioDetailsItem.sosType != 98">
              <span>人为触发(无故障)</span>
              <p style="margin: 10px 0 0 0;">确认关闭</p>
            </el-button>
            <el-button :disabled="dioDetailsItem.sosStatus!=1" size="mini" type="primary" @click="sosClick(6)"
              v-if="dioDetailsItem.sosType == 13">
              <span>确认</span>
              <p style="margin: 10px 0 0 0;">正常报警非误报</p>
            </el-button>
            <el-button size="mini" type="info"
              v-if="dioDetailsItem.sosType != 13&&dioDetailsItem.sosType != 22&&dioDetailsItem.sosType != 109&& dioDetailsItem.sosType != 21 &&dioDetailsItem.sosType != 15&& dioDetailsItem.sosType != 98"
              @click="sosClick(2)" :disabled="dioDetailsItem.sosStatus!=1">
              <span>电梯故障(无困人)</span>
              <p style="margin: 10px 0 0 0;">派发维修工单</p>
            </el-button>
            <el-button size="mini" type="warning"
              v-if="dioDetailsItem.sosType != 22&&dioDetailsItem.sosType != 109&& dioDetailsItem.sosType != 21 &&dioDetailsItem.sosType != 19 &&dioDetailsItem.sosType != 20 && dioDetailsItem.sosType != 15&&dioDetailsItem.sosType != 13&& dioDetailsItem.sosType != 96"
              @click="sosClick(3)" :disabled="dioDetailsItem.sosStatus!=1">
              <span>困人</span>
              <p style="margin: 10px 0 0 0;">立即派发救援工单</p>
            </el-button>
            <el-button class="errorSos" size="mini"
              v-if="dioDetailsItem.sosType != 109&&dioDetailsItem.sosType != 15&& dioDetailsItem.sosType != 98"
              @click="sosClick(5)" :disabled="dioDetailsItem.sosStatus!=1">
              <span>误报</span>
              <p style="margin: 10px 0 0 0;">设备产生错误报警</p>
            </el-button>
          </template>
        </div>
      </span>
      <!-- <div slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: center;">
        <div v-if="detailsItem.seleId==1">
          <el-button :disabled="dioDetailsItem.sosStatus!=1" size="mini" type="primary" @click="sosClick(1)"
            v-if="dioDetailsItem.sosType != 109&&dioDetailsItem.sosType != 15&&dioDetailsItem.sosType != 13&&dioDetailsItem.sosType != 98">
            <span>人为触发(无故障)</span>
            <p style="margin: 10px 0 0 0;">确认关闭</p>
          </el-button>
          <el-button :disabled='dioDetailsItem.sosStatus!=1'
            v-if="dioDetailsItem.sosType != 21 && dioDetailsItem.sosType != 96" size="mini" type="info"
            @click="sosClick(2)">
            <span>电梯故障(无困人)</span>
            <p style="margin: 10px 0 0 0;">派发维修工单</p>
          </el-button>
          <el-button
            :disabled='dioDetailsItem.sosStatus!=1' v-if="dioDetailsItem.sosType != 21&& dioDetailsItem.sosType != 96&&dioDetailsItem.sosType != 20 &&dioDetailsItem.sosType != 19"
            size="mini" type="warning" @click="sosClick(3)">
            <span>困人</span>
            <p style="margin: 10px 0 0 0;">立即派发维修工单</p>
          </el-button>
        </div>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
  import Process from '../../../bjgl/bjjl/components/process'
  import Operational from '../../../bjgl/bjjl/components/operational'
  import AlarmMessage from '../../../bjgl/bjjl/components/alarmMessage'
  import {
    formatDate
  } from '@/util';
  let current_time = formatDate(new Date(), 'yyyy-MM-dd') + ' 00:00:00';
  let current_time1 = new Date(current_time).getTime();
  let starTime = new Date(current_time1 - 30 * 24 * 60 * 60 * 1000);
  let endTime = new Date(current_time1 + 24 * 60 * 60 * 1000 - 1000);

  export default {
    components: {
      Process,
      Operational,
      AlarmMessage,
    },

    props: {
      detailsItem: Object,
      dioDetailsItem: Object,
    },
    data() {
      return {
        dioDetails: true,
      }
    },
    methods: {
      // 详情操作按钮
      sosClick(type) {
        this.dioDetailsItem.sosStatus = 2
        this.$http
          .post('/api/ele/app/sos/submit', {
            id: this.dioDetailsItem.id,
            submitType: type,
            sosTime: this.dioDetailsItem.sosTime
          })
          .then((res) => {
            if (res.data.success) {
              this.$message.success(res.data.msg)
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      closeDetails() {
        this.$emit("closeSosDetails");
        this.dioDetails = false;
      },

    },
    created() {},
  }
</script>
<style lang="scss" scoped>
  .yeScroll {
    overflow-y: scroll;
  }
  .errorSos {
    background: #00dcff;
    border-color: #00dcff;
    color: #fff
  }
</style>
