<template>
  <el-dialog v-if='dialogVisible' :close-on-click-modal='false' :title="tit" :visible.sync="dialogVisible" width="950px"
    top='20vh' @close="dialogClose" append-to-body>
    <div style="height: 40vh;overflow-y: scroll;" class="scrollElement">
      <el-form :rules="rules" ref="ruleForm" label-position="right" label-width="120px" :model="fign">
        <el-row style="margin:10px 0">
          <el-col :span="12">
            <el-form-item label="单位名称:" prop="name">
              <el-input v-model="fign.name" placeholder="请输入" class="einp">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="授权证书编号:" prop="certificateCode">
              <el-input v-model="fign.certificateCode" placeholder="请输入" class="einp">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin:10px 0">
          <el-col :span="12">
            <el-form-item label="单位地址:" prop="address">
              <el-input v-model="fign.address" placeholder="请输入" class="einp">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属区域:" prop="areaCodeArr">
              <el-cascader :props="props" :options="$mapCode" v-model="fign.areaCodeArr" class="einp" collapse-tags
                clearable>
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin:10px 0">
          <el-col :span="12">
            <el-form-item label="单位负责人:" prop="contact">
              <el-input v-model="fign.contact" placeholder="请输入" class="einp">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系方式:" prop="phone">
              <el-input v-model="fign.phone" placeholder="请输入" class="einp">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin:10px 0">
          <el-col :span="12">
            <el-form-item label="统一信用代码:" prop="creditCode">
              <el-input v-model="fign.creditCode" placeholder="请输入" class="einp">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="资格证有效期:">
              <el-date-picker v-model="fign.zTime" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" class="einp" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="!$store.state.isWBJB">
          <el-col :span="12" style="margin:5px 0">
            <el-form-item label="关联品牌:">
              <el-select v-model="fign.brand" filterable clearable placeholder="请选择" class="einp">
                <el-option v-for="item in brandList" :key="item.id" :label="item.brand" :value="item.uniqueCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin:10px 0">
          <el-col :span="12">
            <el-form-item label="资格证:">
              <FileUpload :md5s.sync="fign.certificate" :multiple="false" :limit="1" url='/api/file/web/uploadByOSS' />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" type="primary" @click="dioSub">确 定</el-button>
      <el-button size="small" @click="dialogClose">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
  var validateName = (rule, value, callback) => {
    let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9()（）]{3,30}$/
    // true：必填  false：非必填
    inpYz(rule, value, callback, true, operation, '可输入中文，字母，数值，长度为3-30')
  };
  var validatecertificateCode = (rule, value, callback) => {
    let operation = /^[a-zA-Z0-9]{3,14}$/
    inpYz(rule, value, callback, false, operation, '可输入字母，数值，长度为3-14')
  };
  var validateaddress = (rule, value, callback) => {
    let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,50}$/
    inpYz(rule, value, callback, true, operation, '可输入中文，字母，数值，长度为2-50')
  };
  var validatecontact = (rule, value, callback) => {
    let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,6}$/
    inpYz(rule, value, callback, true, operation, '可输入中文，字母，数值，长度为2-6')
  };
  var validatephone = (rule, value, callback) => {
    // let operation = /^1\d{7,10}$/
    // inpYz(rule, value, callback, true, operation, '手机号码长度为：8-11')
    let operation = /^.{8,20}$/
    inpYz(rule, value, callback, true, operation, '手机号码长度为：8-20')
  };
  var validatecreditCode = (rule, value, callback) => {
    let operation = /^[a-zA-Z0-9]{6,18}$/
    inpYz(rule, value, callback, false, operation, '可输入数值，字母，长度为6-18')
  };
  const pageType = 'TENEMENT_UNIT'
  import FileUpload from '@/components/FileUpload'
  export default {
    components: {
      FileUpload,
    },
    props: ['tit', 'propForm'],
    data() {
      return {
        dialogVisible: true,
        fign: {},
        props: {
          label: "name",
          value: "areaCode",
          checkStrictly: true,
          children: 'children',
          disabled: false,
          // disabled: this.cascadeDisabled,
        },
        rules: {
          name: [{
            required: true,
            validator: validateName,
            trigger: 'blur'
          }],
          certificateCode: [{
            validator: validatecertificateCode,
            trigger: 'blur'
          }],
          address: [{
            required: true,
            validator: validateaddress,
            trigger: 'blur'
          }],
          contact: [{
            required: true,
            validator: validatecontact,
            trigger: 'blur'
          }],
          phone: [{
            required: true,
            validator: validatephone,
            trigger: 'blur'
          }],
          creditCode: [{
            validator: validatecreditCode,
            trigger: 'blur'
          }],
          areaCodeArr: [{
            required: true,
            message: '请选择所属区域',
            trigger: 'change'
          }],
        },
        brandList: [],
      }
    },
    created() {
      // this.onRefer()
      this.getBrand()
      if (this.tit == "修改") {
        this.fign = this.propForm
      }
      // const adminInfo = JSON.parse(sessionStorage.getItem('adminInfo'))
      // const Arr = adminInfo.orgId.split(',')
      // Arr.forEach((item) => {
      //   this.oderArr.push(item.split('-')[1])
      // })
      // this.getNotice('ORG_BRAND')
    },
    methods: {
      dialogClose() {
        this.dialogVisible = false
        this.$emit('close')
      },
      getBrand() {
        const data = {
          current: 1,
          size: 9999,
        }
        this.$http.post(`/api/system/web/brand/getBrandPage`, data)
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.brandList = data.records
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      dioSub() {
        console.log('this.fign', this.fign)

        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let data = JSON.parse(JSON.stringify(this.fign))
            let url = '/api/system/web/org/submit'

            data.certificateStartTime = ''
            data.certificateEndTime = ''
            if (data.zTime) {
              data.certificateStartTime = data.zTime[0]
              data.certificateEndTime = data.zTime[1]
            }
            if (data.areaCodeArr) {
              if (data.areaCodeArr.length > 0) {
                data.areaCodes = JSON.stringify(data.areaCodeArr)
                data.areaCode = data.areaCodeArr[data.areaCodeArr.length - 1]
              }
            }
            data.type = pageType

            console.log('data', data)
            this.$http
              .post(url, data)
              .then((res) => {
                if (res.data.success) {
                  const data={
                    wydwId:res.data.data.id
                  }
                  this.$emit('update',data)
                  this.dialogVisible = false
                  this.$message.success(res.data.msg)
                } else {
                  this.$message.error(res.data.msg)
                }
              })
          } else {
            this.$message.warning('请验证填写内容!');
            return false;
          }
        })
      },
    }
  }
</script>

<style>
</style>
