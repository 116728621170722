<template>
	<el-scrollbar style="float: left;width: 98%;height: 50px;margin-left: 10px;" ref="sclBar" @wheel.native.prevent='scrollEvent'>
		<div ref="sclBars" style="float: left;">

			<el-tag :effect='tag.i==routeDate.i?"dark":"plain"'
				style="margin-right: 5px;margin-left: 5px;cursor: pointer;" v-for="(tag,index) in routeDate.tags"
				:key="tag.name" closable :disable-transitions="true" :type='tag.i==routeDate.i?"":"info"'
				@close="handleClose(tag,index)" @contextmenu.prevent.native="openMenu($event,tag,index)" @click="go(tag)">
				{{tag.name}}
			</el-tag>


			<div :style="{left:sLeft+'px',top:sTop+'px'}" v-show="isCarte.i" class="contextmenu">
				<ul class="contextmenus">
					<li @click="renov()">刷&nbsp;&nbsp;&nbsp;&nbsp;新</li>
					<li @click="shut()">关&nbsp;&nbsp;&nbsp;&nbsp;闭</li>
					<li @click="shutElse()">关闭其他</li>
					<li @click="shutRight()">关闭右侧</li>
				</ul>
			</div>

		</div>
	</el-scrollbar>
</template>

<script>
	export default {
		props: ["routeDate","isCarte",'routerName'],
		data() {
			return {
				sLeft: 0,
				sTop: 0,
				handleIndex: 0,
				handleTag:"",
				hIndex:0
			};
		},
		methods: {
			// 关闭标签事件
			handleClose(tag, index) {
				if (this.routeDate.tags.length == 1) {
          let obj={
            ...this.routerName
          }
					// let obj = {
					// 	name: '首页',
					// 	i: '1',
					// 	rot: "/dashboard"
					// }
					this.routeDate.i = Number(obj.i)
					this.routeDate.tags.push(obj)
					this.$router.push(obj.rot)
				} else {

					if (tag.i == this.routeDate.i) {
						if (this.routeDate.tags[index + 1] != undefined) {
							this.routeDate.i = this.routeDate.tags[index + 1].i
							let r = this.routeDate.tags[index + 1].rot
							this.$router.push(r)
						} else {
							this.routeDate.i = this.routeDate.tags[index - 1].i
							let r = this.routeDate.tags[index - 1].rot
							this.$router.push(r)
						}
					}

				}

				this.routeDate.tags.splice(this.routeDate.tags.indexOf(tag), 1);

			},
			// 自定义滚轮
			scrollEvent(event) {
				const sclObj = this.$refs.sclBar.$refs.wrap
				// 获取滚动数据
				const detail = event.wheelDelta || event.detail;
				// 改变定位
				sclObj.scrollLeft += detail
			},
			// 右键出现菜单事件
			openMenu(e, tag ,index) {
				console.log(e, tag ,index,'e, tag ,index');
				this.isCarte.i = true
				this.sLeft = e.pageX
				this.sTop = 88
				this.handleIndex=tag.i
				this.handleTag=tag
				this.hIndex=index
			},
			// 刷新事件
			renov(){
				let url = this.handleTag.rot
				if(this.$route.path==url){
					this.$router.push('/dashboard/nullPage')
				}else{
					this.$router.push(url)
				}
				this.routeDate.i = this.handleTag.i
			},
			// 关闭
			shut(){
				this.handleClose(this.handleTag , this.hIndex)
			},
			// 关闭其他
			shutElse(){
				if(this.routeDate.tags.length>1){
					this.routeDate.tags.splice(0,this.routeDate.tags.length)

					this.routeDate.tags.push(this.handleTag)
					this.routeDate.i = this.handleTag.i
					this.$router.push(this.handleTag.rot)
				}
			},
			// 关闭右侧
			shutRight(){
				if(this.routeDate.tags.length>=1){
					let number = this.routeDate.tags.findIndex(val => (val.name === this.handleTag.name))
					this.routeDate.tags.splice(number + 1, this.routeDate.tags.length)
					this.routeDate.i = this.handleTag.i
					this.$router.push(this.handleTag.rot)
				}
			},
			// 关闭所有
			shutAll(){
				if(this.routeDate.tags.length>=1){
					this.routeDate.tags.splice(0,this.routeDate.tags.length)
					let obj = {
						name: '首页',
						i: '1',
						rot: "/dashboard"
					}
					this.routeDate.i = 1
					this.$router.push(obj.rot)
					this.routeDate.tags.push(obj)
				}
			},
			// 顶级标签跳转事件
			go(tag) {
				if(this.routeDate.i != tag.i){
					this.$router.push(tag.rot)
					this.routeDate.i = tag.i
				}
				this.$emit('goTag', tag)
			}
		}

	}
</script>

<style>
	.el-scrollbar__bar.is-horizontal>div {
	    height: 100% !important;
	}

	.el-scrollbar .el-scrollbar__wrap .el-scrollbar__view {
		white-space: nowrap;
		height: 100%;
	}
	/* 菜单样式 */

	.contextmenus {
		margin: 0;
		padding: 0px;
		color: #333;
		font-size: 12px;
		line-height: 30px;
		width: 100%;
	}

	li {
		cursor: pointer;
		list-style: none;
		text-align: center;
	}



	.contextmenu {
		width: 80px;
		height: 130px;
		background: white;
		position: fixed;
		z-index: 999999999;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;

	}
</style>


<style scoped>

	.contextmenus li:hover {
		background: #409eff;
		color: #fff
	}
	.el-scrollbar >>>.el-scrollbar__wrap{
		overflow: hidden !important;
	}

	li:hover {
		background: #eee;
	}

	.el-scrollbar__thumb{
		overflow: hidden;
	}
</style>
