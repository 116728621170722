<template>
  <div>
    <el-upload
      v-loading="loading"
      :class="{'el-disabled': (!isMultiple && comsFileUploadImageList.length > 0), 'uploadBannerTips' : showBannerTips}"
      :action="url"
      :file-list="comsFileUploadImageList"
      :on-success="comsFileUploadImageSuccess"
      :on-remove="comsFileUploadImageRemove"
      :on-preview="comsFileUploadImagePreview"
      :before-upload="comsFileUploadImageBefore"
      :multiple="isMultiple"
      :disabled="deleteSHow"
      class="components-file-upload"
      list-type="picture-card"
      accept=".jpg, .jpeg, .png, .JPG, .JPEG"
    >
      <i v-if="!(!isMultiple && comsFileUploadImageList.length > 0)" class="el-icon-plus" />
      <div slot="tip" v-if="showBannerTips" class="el-upload__tip showBannerTips">上传单张图片，格式为png，jpg,尺寸为640*255px，不超过1M。</div>
    </el-upload>
    <el-dialog :visible.sync="comsFileUploadPreviewVisible" append-to-body width="950px" top="8vh">
      <div
        style="height: 50vh; overflow-y: scroll;padding: 20px"
        class="scrollElement"
      >
      <el-image fit="contain" :src="comsFileUploadPreviewImageUrl"  style="width:100%;height:100%;" alt/>
      </div>
      <div slot="footer" class="dialog-footer">
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    md5s: {
      type: String
    },
    url: {
      type: String
    },
    limit: {
      type: Number,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    callback: {
      type: String,
      default: null
    },
    size: {
      type: Number,
      default: 2
    },
    maxWidth: {
      type: Number,
      default: 400
    },
    maxHeight: {
      type: Number,
      default: 400
    },
    showBannerTips: {
      type: Boolean,
      default: false
    },
    showSize: {
      type: Boolean,
      default: true
    },
    deleteSHow : {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      comsFileUploadPreviewVisible: false,
      comsFileUploadPreviewImageUrl: null, // 文件地址
      comsFileUploadImageList: [], // 选择的文件列表
      hasDownloadOldImages: false, //  是否下载图片
      loading: false
    }
  },
  computed: {
    isMultiple: {
      get () {
        return this.multiple
      }
    }
  },
  watch: {
    async md5s (newVal) {
      // 直接赋值
      this.comsFileUploadImageList = [{ url: newVal }]

      if (this.md5s && this.md5s !== '' && !this.hasDownloadOldImages) {
        this.comsFileUploadImageList = [{ url: this.md5s }]
        this.hasDownloadOldImages = true
      } else {
        if (!this.md5s || this.md5s === '') {
          this.comsFileUploadImageList = []
        }
        this.hasDownloadOldImages = false
      }
    }
  },
  async created () {
    if (this.md5s && this.md5s !== '') {
      this.comsFileUploadImageList = [{ url: this.md5s }]
      this.hasDownloadOldImages = true
    } else {
      this.comsFileUploadImageList = []
    }
  },
  methods: {
    async comsFileUploadImageGetUrl (md5s) {
      if (!md5s || md5s === '') return []
      this.loading = true
      const res = await this.$http({
        url: this.url,
        method: 'POST',
        data: { md5s: md5s }
      })
      this.loading = false
      if (res.code === 0) {
        return res.data.list
      }
      return []
    },
    comsFileUploadImageSuccess (res, file) {
      if (res.code === 200 && res.data.imgurl) {
        this.$emit('update:md5s', res.data.imgurl)
        if (this.callback) {
          this.$emit(this.callback, { md5s: res.data.imgurl })
        }
      } else {
        this.$message.error(res.msg ? res.msg : '文件上传失败')
        this.comsFileUploadImageRemove(res, file)
      }
    },
    comsFileUploadImageRemove (res, fileList) {
      // if (res.code === 0) {
      let _md5s = []
      fileList.forEach(item => {
        if (item.md5) {
          _md5s.push(item.md5)
        } else {
          if (item.response.data.success_list) {
            item.response.data.success_list.forEach(val => {
              _md5s.push(val.md5)
            })
          }
        }
      })
      if (_md5s.length === 0) this.comsFileUploadImageList = []
      _md5s = _md5s.join(',')
      setTimeout(() => {
        this.$emit('update:md5s', _md5s)
        if (this.callback) {
          this.$emit(this.callback, { md5s: _md5s })
        }
      }, 500)
      // }
    },
    comsFileUploadImagePreview (file) {
      this.comsFileUploadPreviewImageUrl = file.url
      this.comsFileUploadPreviewVisible = true
    },

    // 限制图片尺寸
    limitFileWH(E_width, E_height, file) {
      const _this = this
      let imgWidth = ''
      let imgHight = ''
      const isSize = new Promise(function(resolve, reject) {
        const width = E_width
        const height = E_height
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function() {
          imgWidth = img.width
          imgHight = img.height
          const valid = img.width <= width && img.height <= height
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return true
      }, () => {
        _this.$message.warning({
          message: '上传图片的尺寸应为' + E_width + '*' + E_height + '，当前上传图片的尺寸为：' + imgWidth + '*' + imgHight,
          btn: false
        })
        return false
      })
      return isSize
    },

      comsFileUploadImageBefore (file) {
        return new Promise((resolve, reject) => {
          const suffix = file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg'
          const isLt1M = file.size / 1024 / 1024 < this.size
          if (!suffix) {
            this.$message.error('只能上传图片！')
            return reject(false)
          }
          if (!isLt1M) {
            this.$message.error(`上传图片大小不能超过 ${this.size}MB!`)
            return reject(false)
          }
          // 调用[限制图片尺寸]函数
          if (this.showSize) {
            console.log(this.showSize,this.maxWidth, this.maxHeight,666)
            this.limitFileWH(this.maxWidth, this.maxHeight, file).then((res) => {
              file.isFlag = res
              if (file.isFlag) {
                return resolve(true)
              } else {
                return reject(false)
              }
            })
          // } else {
          //   return resolve(true)
          }else{
              return resolve(true)
          }
        })
      },
    }
	}
</script>

<style lang="scss">
.components-file-upload {
  .el-upload--picture-card {
    height: 100px;
    width: 100px;
    line-height: 104px;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    height: 100px;
    width: 100px;
  }
}
.uploadBannerTips{
  .el-upload-list--picture-card .el-upload-list__item {
    height: 128px;
    width: 320px;
  }
}
.el-disabled .el-upload--picture-card {
  display: none;
}
</style>
