<template>
  <el-dialog v-if='dialogVisible' :close-on-click-modal='false' :title="tit" :visible.sync="dialogVisible" width="950px"
    top='20vh' @close="dialogClose" append-to-body>
    <div style="height: 30vh;overflow-y: scroll;" class="scrollElement">
      <el-form :rules="rules" ref="ruleForm" label-position="right" label-width="100px" :model="fign">
        <el-row style="margin:10px 0">
          <el-col :span="12">
            <el-form-item label="单位名称:" prop="name">
              <el-input v-model="fign.name" placeholder="请输入" class="einp">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位类型:" prop="type">
              <el-select v-model="fign.type" placeholder="请输入" class="einp" :disabled="disabled" @change="getinfoType">
                <el-option v-for="item in typeDate.OTHER_UNIT" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin:10px 0">
          <el-col :span="12">
            <el-form-item label="单位地址:" prop="address">
              <el-input v-model="fign.address" placeholder="请输入" class="einp">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属区域:" prop="areaCodeArr">
              <el-cascader :props="props" :options="$mapCode" v-model="fign.areaCodeArr" class="einp" collapse-tags
                clearable>
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin:10px 0">
          <el-col :span="12">
            <el-form-item label="单位负责人:" prop="contact">
              <el-input v-model="fign.contact" placeholder="请输入" class="einp">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系方式:" prop="phone">
              <el-input v-model="fign.phone" placeholder="请输入" class="einp">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" type="primary" @click="dioSub">确 定</el-button>
      <el-button size="small" @click="dialogClose">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
  var validateName = (rule, value, callback) => {
    let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9()（）]{3,30}$/
    // true：必填  false：非必填
    inpYz(rule, value, callback, true, operation, '可输入中文，字母，数值，长度为3-30')
  };
  var validateaddress = (rule, value, callback) => {
    let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,25}$/
    inpYz(rule, value, callback, true, operation, '可输入中文，字母，数值，长度为2-25')
  };
  var validatecontact = (rule, value, callback) => {
    let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,6}$/
    inpYz(rule, value, callback, true, operation, '可输入中文，字母，数值，长度为2-6')
  };
  var validatephone = (rule, value, callback) => {
    // let operation = /^1\d{7,10}$/
    // inpYz(rule, value, callback, true, operation, '手机号码长度为：8-11')
    let operation = /^.{8,20}$/
    inpYz(rule, value, callback, true, operation, '手机号码长度为：8-20')
  };
  const pageType = 'OTHER_UNIT'
  import FileUpload from '@/components/FileUpload'
  export default {
    components: {
      FileUpload,
    },
    props: ['tit', 'propForm', 'qtdwType'],
    data() {
      return {
        dialogVisible: true,
        typeDate: [],
        disabled: false,
        props: {
          label: 'name',
          value: 'areaCode',
          checkStrictly: true,
          children: 'children',
          disabled: false,
        },
        loading: true,
        fign: {
          type:''
        },
        rules: {
          name: [{
            required: true,
            validator: validateName,
            trigger: 'blur'
          }],
          address: [{
            required: true,
            validator: validateaddress,
            trigger: 'blur'
          }],
          contact: [{
            required: true,
            validator: validatecontact,
            trigger: 'blur'
          }],
          phone: [{
            required: true,
            validator: validatephone,
            trigger: 'blur'
          }],
          areaCodeArr: [{
            required: true,
            message: '请选择所属区域',
            trigger: 'change'
          }],
          type: [{
            required: true,
            message: '请选择单位类型',
            trigger: 'change'
          }],
        },
      }
    },
    created() {
      // this.onRefer()
      if (this.tit == "修改") {
        this.fign = this.propForm
      }
      this.disabled = this.qtdwType ? true : false
      this.getDictionar(["OTHER_UNIT", "UNIT_LIST"]);
      this.$nextTick(() => {
        this.fign.type = this.qtdwType ? this.qtdwType : this.fign.type
        setTimeout(() => {
          this.loading = false
        }, 1500)
      })
      // const adminInfo = JSON.parse(sessionStorage.getItem('adminInfo'))
      // const Arr = adminInfo.orgId.split(',')
      // Arr.forEach((item) => {
      //   this.oderArr.push(item.split('-')[1])
      // })
      // this.getNotice('ORG_BRAND')
    },
    methods: {
      getinfoType(data) {
        console.log(data, '用户类型')
        // this.fign.type = data
        this.$set(this.fign,'type',data)
        // this.$forceUpdate()
      },
      // 获取字典数据
      getDictionar(code) {
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            if (res.data.code === 200) {
              const {
                data: {
                  data
                }
              } = res;
              this.typeDate = data

            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
      dialogClose() {
        this.dialogVisible = false
        this.$emit('close')
      },
      dioSub() {
        console.log('this.fign', this.fign)

        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let data = JSON.parse(JSON.stringify(this.fign))
            let url = '/api/system/web/org/submit'

            if (data.zTime) {
              data.certificateStartTime = data.zTime[0]
              data.certificateEndTime = data.zTime[1]
            }
            if (data.areaCodeArr) {
              if (data.areaCodeArr.length > 0) {
                data.areaCodes = JSON.stringify(data.areaCodeArr)
                data.areaCode = data.areaCodeArr[data.areaCodeArr.length - 1]
              }
            }
            // 设置为其他单位
            data.isOther = 1

            console.log('data', data)
            this.$http
              .post(url, data)
              .then((res) => {
                if (res.data.success) {
                  const data = {
                    qtdwId: res.data.data.id,
                    type: this.fign.type
                  }
                  this.$emit('update', data)
                  this.dialogVisible = false
                  this.$message.success(res.data.msg)
                } else {
                  this.$message.error(res.data.msg)
                }
              })
          } else {
            this.$message.warning('请验证填写内容!');
            return false;
          }
        })
      },
    }
  }
</script>

<style>
</style>
