<template>
  <div class="admin-project-myproject-container">
    <!-- <template v-if="tableData != 0"> -->
    <div class="tipTitle" style="padding: 0px 50px 15px 0px;">
      <!-- <h4>运行报告列表</h4> -->
      <el-button @click="openCreate" type="success" size="small">生成报告
      </el-button>
      <el-button type="text" @click="onRefer">刷新</el-button>
    </div>
    <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" height="450px">
      <el-table-column type="index" label="序号" width="50px" />
      <el-table-column align="center" label="生成状态" min-width="100px">
        <template slot-scope="scope">
          {{
              scope.row.status == 1
                ? "已完成"
                : scope.row.status == 2
                ? "生成失败"
                : "生成中"
            }}
        </template>
      </el-table-column>

      <el-table-column align="center" label="数据时间范围" min-width="150px">
        <template slot-scope="scope">
          {{ scope.row.startTime ? scope.row.startTime.split(" ")[0] : "-" }}
          至
          {{ scope.row.endTime ? scope.row.endTime.split(" ")[0] : "-" }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="createTime" label="申请时间" min-width="150px">
        <template slot-scope="scope">
          {{ scope.row.createTime ? scope.row.createTime : "-" }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="address" label="操作" width="200px">
        <template slot-scope="scope">
          <el-button :disabled="scope.row.status == 0 || scope.row.status == 2"
            @click="actionBtn(scope.row.url, 'download')" type="primary" size="small">下载
          </el-button>
          <!-- <el-button
              :disabled="scope.row.status == 0 || scope.row.status == 2"
              @click="actionBtn(scope.row.url, 'detail')"
              type="primary"
              size="small"
              >详情
            </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="tableTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
      @pagination="dictionarPage" />
    <!-- </template>
    <div class="nullDate" v-else v-loading="tableLoading">
      <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
    </div> -->

    <CreateReport ref="CreateReport" v-if="createReportVisible" :elevatorId="elevatorId" @closeCreate="closeCreate" />
  </div>
</template>

<script>
  import axios from 'axios'
  import Pagination from "@/components/Pagination";
  import CreateReport from './createReport.vue';
  export default {
    components: {
      Pagination,
      CreateReport
    },

    props: {
      elevatorId: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        // 表格参数
        formInline: {
          current: 1,
          size: 10,
        },
        tableData: [],
        // 分页参数
        tableTotal: 0,
        createReportVisible: false,
        tableLoading: true

      };
    },
    methods: {
      actionBtn(pdfUrl, type) {
        const x = new XMLHttpRequest()
        x.open('GET', pdfUrl, true)
        x.responseType = 'blob'
        x.onload = function() {
          const url = window.URL.createObjectURL(x.response)
          const a = document.createElement('a')
          a.href = url
          a.download = '运行报告.pdf'
          a.click()
          document.body.removeChild(a)
          // 然后移除
        }
        x.send()
      },


      openCreate() {
        this.createReportVisible = true
      },
      closeCreate() {
        this.onRefer();
        this.createReportVisible = false
      },
      // 分页
      dictionarPage(data) {
        this.formInline.current = data.page;
        this.formInline.size = data.limit;
        this.onRefer();
      },
      // 表格方法
      onRefer(ishm) {
        this.tableLoading = true
        this.$http
          .get(
            `/api/ele/web/operationalReport/list?type=${1}&eleId=${this.elevatorId}&current=${this.formInline.current}&size=${this.formInline.size}`
          )
          .then((res) => {
            if (res.data.code === 200) {
              const {
                data: {
                  data
                },
              } = res;
              this.tableData = data.records ?? [];
              this.tableTotal = data.total ?? 0;
            } else {
              this.$message.error(res.data.msg);
            }
            this.tableLoading = false
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tipTitle {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
