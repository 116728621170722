<template>
  <el-dialog
    title="报告示例"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="1100px"
    top="8vh"
    @close="closeDialog"
  >
    <div class="scrollElement" style="height: 70vh; overflow-y: scroll; padding: 15px">
        <div class="box">
          <h4>电梯详细资料</h4>
          <el-row>
            <el-col :span="12" class="title">
              电梯名称: 张三的电梯名称
            </el-col>
            <el-col :span="12" class="title">
              电梯注册代码: 123123123123
            </el-col>
            <el-col :span="12" class="title">
              救援识别码: 2312312312
            </el-col>
            <el-col :span="12" class="title">
              出厂编号: 123123123
            </el-col>
            <el-col :span="12" class="title">
              AI摄像头ID: 23123123123123
            </el-col>
            <el-col :span="12" class="title">
              所属小区: 张三的小区
            </el-col>
            <el-col :span="12" class="title">
              报警短信推送: 不推送报警短信
            </el-col>
            <el-col :span="12" class="title">
              楼栋: 3栋
            </el-col>
            <el-col :span="12" class="title">
              单元号: 3栋
            </el-col>
            <el-col :span="12" class="title">
              电梯号: 3栋
            </el-col>
            <el-col :span="12" class="title">
              电梯注册日期: 2023-12-12
            </el-col>
            <el-col :span="12" class="title">
              电梯额定负载: 123 kg
            </el-col>
            <el-col :span="12" class="title">
              电梯使用场所: 住宅
            </el-col>
            <el-col :span="12" class="title">
              电梯层站数: 22
            </el-col>
            <el-col :span="12" class="title">
              电梯控制方式: 液压
            </el-col>
            <el-col :span="12" class="title">
              电梯品牌: 日立
            </el-col>
            <el-col :span="12" class="title">
              于系统激活时间: 2023-12-12 12:12:12
            </el-col>
            <el-col :span="12" class="title">
              电梯投保单位: 张三的保险单位
            </el-col>
            <el-col :span="12" class="title">
              电梯保险: 电梯在保
            </el-col>
            <el-col :span="12" class="title">
              电梯维保单位: 张三的维保单位
            </el-col>
            <el-col :span="12" class="title">
              电梯维保人员: 张三(189****3451) ，李四(189****3451)
            </el-col>
            <el-col :span="12" class="title">
              电梯监管单位: 张三的监管单位
            </el-col>
            <el-col :span="12" class="title">
              物业单位: 张三的物业单位
            </el-col>
            <el-col :span="12" class="title">
              电梯安全员: 张三(189****3451) ，李四 (189****3451)
            </el-col>
            <el-col :span="24" class="title">
              电梯监管单位: 张三的监管单位
            </el-col>
            <el-col :span="24" class="title">
              电梯安装位置: 深圳市
            </el-col>
          </el-row>
        </div>
        <div class="box">
          <h4>运行情况</h4>
          <el-row>
            <h4>1.累计运行时间</h4>
            <el-col :span="24" class="title">
              <LineChart
                v-if="showCharts"
                :sosLineData="runsTimeLineData"
                :trendColor="trendColor"
                :height="'170px'"
              />
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <div class="numTop">
              <h4>2.高峰运行时段统计(张三的电梯名称)</h4>
              <div class="disFlex" style="width:50%;">
                  <div v-for="(item, index) in numBox" :key="index" class="disFlex">
                    {{item}} <div :class="`countBox${index + 1} numBox`"></div>
                  </div>
              </div>
            </div>
            <el-col :span="1" v-for="(item, index) in hourArr" :key="item" style="text-align: center; margin-top: 10px">
              <div class="countBox" :class="countBoxBg(personArr[index])">
                {{personArr[index]}}
              </div>
              {{item}}时
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <h4>3.启停楼层统计 (张三的电梯名称)</h4>
            <el-col :span="1" v-for="(item, index) in hourArr" :key="item" style="text-align: center; margin-top: 10px">
              <div class="countBox" :class="countBoxBg(personArr[index])">
                {{personArr[index]}}
              </div>
              {{item}}楼
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <h4>4.每日梯人 (张三电梯名称)</h4>
            <el-col :span="1" v-for="(item, index) in hourArr" :key="item" style="text-align: center; margin-top: 10px">
              <div class="countBox" :class="countBoxBg(personArr[index])">
                {{personArr[index]}}
              </div>
              {{item}}号
            </el-col>
          </el-row>
        </div>
        <div class="box">
          <h4>维保统计</h4>
          <div class="disFlex" style="justify-content: center; margin-bottom: 15px">
            <div v-for="item in maintenArr" :key="item.name" class="titleBox">
              <div class="titleBoxTop">{{item.name}}</div>
              <div class="titleBoxBottom">{{item.num}}</div>
            </div>
          </div>
          <h4>维保明细</h4>
          <el-row>
            <el-col :span="8" class="title">
              计划时间: 2023年8月23日
            </el-col>
            <el-col :span="8" class="title">
              签到时间: 2022年3月12日 12:12:12
            </el-col>
            <el-col :span="8" class="title">
              完成时间: 2022年3月12日 12:12:12
            </el-col>
            <el-col :span="8" class="title">
              维保类型: 半月保
            </el-col>
            <el-col :span="8" class="title">
              维保时长: 12h12m
            </el-col>
            <el-col :span="8" class="title">
              签到经纬度: 114.121679，22.652016
            </el-col>
            <el-col :span="24" class="title">
              电梯监管单位: 张三的监管单位
            </el-col>
            <el-col :span="24" class="title">
              电梯安装位置: 深圳市
            </el-col>
          </el-row>
          <el-table :data="tableData" ref="multipleTable" style="width: 100%;margin-bottom: 20px;">
            <el-table-column type="index" label="序号" width="50px" />
            <el-table-column align='center' prop="name" label="维保项" />
            <el-table-column align='center' prop="status" label="状态" />
          </el-table>
          <el-row style="margin-top: 15px">
            <el-col :span="8" class="title">
              计划时间: 2023年8月23日
            </el-col>
            <el-col :span="8" class="title">
              签到时间: 2022年3月12日 12:12:12
            </el-col>
            <el-col :span="8" class="title">
              完成时间: 2022年3月12日 12:12:12
            </el-col>
            <el-col :span="8" class="title">
              维保类型: 半月保
            </el-col>
            <el-col :span="8" class="title">
              维保时长: 12h12m
            </el-col>
            <el-col :span="8" class="title">
              签到经纬度: 114.121679，22.652016
            </el-col>
            <el-col :span="24" class="title">
              电梯监管单位: 张三的监管单位
            </el-col>
            <el-col :span="24" class="title">
              电梯安装位置: 深圳市
            </el-col>
          </el-row>
          <el-table :data="tableData" ref="multipleTable" style="width: 100%;margin-bottom: 20px;">
            <el-table-column type="index" label="序号" width="50px" />
            <el-table-column align='center' prop="name" label="维保项" />
            <el-table-column align='center' prop="status" label="状态" />
          </el-table>
        </div>
        <div class="box">
          <h4>维修统计</h4>
          <div class="disFlex" style="justify-content: center; margin-bottom: 15px">
            <div v-for="item in serviceArr" :key="item.name" class="titleBox">
              <div class="titleBoxTop">{{item.name}}</div>
              <div class="titleBoxBottom">{{item.num}}</div>
            </div>
          </div>
          <h4>维修明细</h4>
          <el-row>
            <el-col :span="8" class="title">
              故障报告时间:2023年8月23日
            </el-col>
            <el-col :span="8" class="title">
              电梯停运时间:2022年3月12日12:12:12
            </el-col>
            <el-col :span="8" class="title">
              完成时间:2022年3月12日 12:12:12
            </el-col>
            <el-col :span="8" class="title">
              故障来源:巡逻发现
            </el-col>
            <el-col :span="8" class="title">
              状态:已完成
            </el-col>
            <el-col :span="24" class="title">
              电梯监管单位: 张三的监管单位
            </el-col>
            <el-col :span="24" class="title">
              电梯安装位置: 深圳市
            </el-col>
          </el-row>
          <el-row style="margin-top: 15px">
            <el-col :span="8" class="title">
              故障报告时间:2023年8月23日
            </el-col>
            <el-col :span="8" class="title">
              电梯停运时间:2022年3月12日12:12:12
            </el-col>
            <el-col :span="8" class="title">
              完成时间:2022年3月12日 12:12:12
            </el-col>
            <el-col :span="8" class="title">
              故障来源:巡逻发现
            </el-col>
            <el-col :span="8" class="title">
              状态:已完成
            </el-col>
            <el-col :span="24" class="title">
              电梯监管单位: 张三的监管单位
            </el-col>
            <el-col :span="24" class="title">
              电梯安装位置: 深圳市
            </el-col>
          </el-row>
        </div>
        <div class="box">

          <h4>报警明细</h4>
          <div class="disFlex" style="justify-content: center;margin-bottom: 20px; background: #dbf4ff;">
            <div style="width: 30%" class="sosLeft">
              <div>1.困人报警: 45%(123次)</div>
              <div>2.困人报警: 45%(123次)</div>
              <div>3.困人报警: 45%(123次)</div>
              <div>4.困人报警: 45%(123次)</div>
              <div>5.困人报警: 45%(123次)</div>
            </div>
            <div style="width: 70%">
              <LineChart
                v-if="showCharts"
                :sosLineData="runsTimeLineData"
                :trendColor="trendColor"
                :height="'170px'"
              />
            </div>
          </div>
          <div class="disFlex" style="justify-content: center;">
            <div v-for="item in sosArr.slice(0, 4)" :key="item.name" class="titleBox">
              <div class="titleBoxTop">{{item.name}}</div>
              <div class="titleBoxBottom">{{item.num}}</div>
            </div>
          </div>
          <div class="disFlex" style="justify-content: center;">
            <div v-for="item in sosArr.slice(4, 9)" :key="item.name" class="titleBox">
              <div class="titleBoxTop">{{item.name}}</div>
              <div class="titleBoxBottom">{{item.num}}</div>
            </div>
          </div>
        </div>
        <div class="box">
          <h4>电梯保险</h4>
          <div class="disFlex" style="justify-content: center; margin-bottom: 15px">
            <div v-for="item in insureArr" :key="item.name" class="titleBox">
              <div class="titleBoxTop">{{item.name}}</div>
              <div class="titleBoxBottom">{{item.num}}</div>
            </div>
          </div>
          <h4>理赔记录</h4>
          <el-row>
            <el-col :span="8" class="title">
              保险单号: 12312312
            </el-col>
            <el-col :span="8" class="title">
              保险公司: 张三的保险公司
            </el-col>
            <el-col :span="8" class="title">
              投保单位: 张三的投保单位
            </el-col>
            <el-col :span="8" class="title">
              受理时间: 2022年3月12日 12:12:12
            </el-col>
            <el-col :span="8" class="title">
              受理人: 张三
            </el-col>
            <el-col :span="8" class="title">
              理赔状态: 已完成
            </el-col>
          </el-row>
          <el-row style="margin-top: 15px">
            <el-col :span="8" class="title">
              保险单号: 12312312
            </el-col>
            <el-col :span="8" class="title">
              保险公司: 张三的保险公司
            </el-col>
            <el-col :span="8" class="title">
              投保单位: 张三的投保单位
            </el-col>
            <el-col :span="8" class="title">
              受理时间: 2022年3月12日 12:12:12
            </el-col>
            <el-col :span="8" class="title">
              受理人: 张三
            </el-col>
            <el-col :span="8" class="title">
              理赔状态: 已完成
            </el-col>
          </el-row>
        </div>
    </div>

    <span slot="footer" class="dialog-footer"> </span>
  </el-dialog>
</template>

<script>
import LineChart from './components/lineChart.vue';
export default {
  components: { LineChart },
  data() {
    return {
      loading: true,
      dialogVisible: true,
      showCharts: false,
      trendColor: ['#ff0000'],
      runsTimeLineData: [{
        "timeData": [ "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"]
      }],
      hourArr: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
      personArr: ['0', '57', '20', '80', '123', '550', '203', '0', '0', '123', '45', '65', '456', '7873', '145', '6', '456', '564', '55', '23', '24', '212', '102', '23'],
      numBox: ['1~49', '50-99', '100~199', '200-299', '300+'],
      maintenArr: [
        {name: '维保计划',num: '2次'},
        { name: '按时维保', num: '2次'},
        { name: '超期维保',num: '2次'},
        { name: '平均维保用时', num: '12H 23M' }],
      serviceArr: [
        {name: '维修次数',num: '2次'},
        {name: '完成次数',num: '2次'},
        {name: '未完成次数',num: '2次'},
        {name: '维修平均用时',num: '12H 23M'}
        ],
      insureArr: [
        {name: '是否在保',num: '是'},
        {name: '保险到期时间',num: '2032-12-12'},
        {name: '保险类型',num: '电梯安全责任险'}
      ],
      sosArr: [
        {name: '累计报警次数', num: '2次'},
        {name: '待处理报警',num: '1231 个'},
        {name: '已处理报警',num: '2次'},
        {name: '已接警次数',num: '2次'},
        {name: '报警衍生救援任务',num: '23个'},
        {name: '严重乘梯安全报警',num: '1231个'},
        {name: '安全预警报警',num: '2次2'},
        {name: '安全提示报警',num: '2次'}
      ],
      tableData: [
        { name: '层门门锁电气触点清洁。触点接触良好，接线可靠', status: '正常'},
        { name: '层门门锁电气触点清洁。触点接触良好，接线可靠', status: '异常'},
        { name: '层门门锁电气触点清洁。触点接触良好，接线可靠', status: '正常'}
      ]
    };
  },
  props: {
    elevatorId: {
      type: String,
      default: "",
    },
    parameterType: {
      type: Object,
      default: () => ({}),
    },
  },

  created() {},
  mounted() {
    this.$nextTick(() => {
      this.showCharts = true
    })
  },
  destroyed() {},

  methods: {
    countBoxBg(num){
			let className = ''
			if (num < 50) {
				className = 'countBox1'
			} else if (num < 100) {
				className = 'countBox2'
			} else if (num < 200) {
				className = 'countBox3'
			} else if (num < 300) {
				className = 'countBox4'
			} else if (num > 300) {
				className = 'countBox5'
			} else {
				className = 'countBox1'
			}
			return className
		},
    closeDialog() {
      this.dialogVisible = false;
      this.$emit("closeCheck");
    },
  },
};
</script>
<style lang="scss" scoped>

.box h4{
  font-size: 18px;
  margin: 0 0 10px;
  font-weight: bolder;
}
.box .title{
  margin: 10px 0;
  font-weight: 600;
}
.numTop{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.disFlex{
  display:flex;
  justify-content: space-around;
  align-items: center;
}
.numBox{
  width: 15px;
  height: 15px;
  margin-left: 10px;
}
.countBox {
  border: solid #149adf 1px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.countBox1 {
  background: #fff;
  color: #000;
}

.countBox2 {
  background: rgb(19, 118, 211);
  color: #000;
}

.countBox3 {
  background: rgb(103, 132, 226);
  color: #000;
}

.countBox4 {
  background: rgb(68, 101, 189);
  color: #000;
}

.countBox5 {
  background: rgb(11, 51, 230);
  color: #000;
}
.titleBox{
  background: #dbf4ff;
  color: #000;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}
.titleBoxTop{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.sosLeft{
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 160px;
  align-items: center;
  color: #000;
}
</style>
